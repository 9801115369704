import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const NewestWords = data => {
  const group = data.data.posts.edges.sort((a, b) =>
    b.node.publishedAt.localeCompare(a.node.publishedAt)
  )
  const quene = group.slice(0, 5)
  return (
    <div>
      <Header> Nyeste ord </Header>
      {quene.map(item => (
        <div key={item.node.id}>
          <StyledLink to={`/${item.node.slug.current}`}>
            <span>
              <span>
                {item.node.abbreviation
                  ? `${item.node.abbreviation.current} - `
                  : ""}
              </span>
              {item.node.title}
            </span>
          </StyledLink>
        </div>
      ))}
    </div>
  )
}
export default NewestWords

const StyledLink = styled(Link)`
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  opacity: 0.8;
  margin-left: 2vh;
  :hover {
    opacity: 1;
  }
`
const Header = styled.h1`
  font-size: 25px;
  font-weight: bold;
  margin-left: 10px;
`
