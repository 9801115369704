import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { Layout, Wrapper } from "@staccx/base"
import PageLayout from "../components/PageLayout"
import DefaultLayout from "../layouts"
import GraphQLErrorList from "../components/graphql-error-list"
import SEO from "../components/seo"
import NewestWords from "../components/NewestWords"

export const query = graphql`
  query ListPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    posts: allSanityPost(
      sort: { fields: [title], order: ASC }
      filter: {
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
        status: { eq: "accepted" }
      }
    ) {
      edges {
        node {
          id
          publishedAt
          title
          _rawBody
          _rawExcerpt
          slug {
            current
          }
          abbreviation {
            current
          }
        }
      }
    }
  }
`

const ListPage = props => {
  const { data, errors } = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const group = data.posts.edges
    .sort((a, b) => a.node.slug.current.localeCompare(b.node.slug.current))
    .reduce((r, e) => {
      const key = e.node.slug.current[0]
      if (!r[key]) r[key] = []
      r[key].push(e)
      return r
    }, {})
  return (
    <DefaultLayout>
      <SEO title="Whatis.Finance" />
      <PageLayout siteTitle={data.site.title}>
        <Wrapper>
          <Layout paddingTop={"huge"}>
            <NewestWords data={data} />
            <AllHeader>Alle ord A-Å</AllHeader>
            {Object.entries(group).map(([key, value], i) => {
              return (
                <div key={i}>
                  <AllHeader>{key.toUpperCase()}</AllHeader>
                  {value.map((item, j) => (
                    <GroupDiv key={j}>
                      <StyledLink to={`/${item.node.slug.current}`}>
                        <span>
                          <span>
                            {item.node.abbreviation
                              ? `${item.node.abbreviation.current} - `
                              : ""}
                          </span>
                          {item.node.title}
                        </span>
                      </StyledLink>
                    </GroupDiv>
                  ))}
                </div>
              )
            })}
          </Layout>
        </Wrapper>
      </PageLayout>
    </DefaultLayout>
  )
}

export default ListPage

const GroupDiv = styled.div`
  padding: 2px;
`

const AllHeader = styled.h1`
  font-size: 25px;
  font-weight: bold;
  margin-left: 10px;
`

const StyledLink = styled(Link)`
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  opacity: 0.8;
  margin: 7px;
  :hover {
    opacity: 1;
  }
`
